<template>
  <b-card>
    <div class="mb-2 d-flex justify-content-end">
      <!-- Table Top -->
      <b-form-input
        v-model="searchQuery"
        class="d-inline-block mr-1"
        :placeholder="$t('search')"
        @input="fetchIngredients()"
      />
      <b-button variant="success" @click="updateCodes()">{{
        $t("save")
      }}</b-button>
    </div>
    <div>
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <b-table
          ref="refIngredienttListTable"
          :items="ingredients"
          striped
          :small="false"
          :fields="fields"
          responsive
          show-empty
          :empty-text="$t('noRecords')"
        >
          <template #cell(newKod)="data">
            <div class="editable">
              <b-form-input
                :id="data.item.ingredientname + data.index + 'code'"
                :value="data.item.oldcode"
                :data-id="'ingredient-' + data.index"
                class="editable text-right"
                autocomplete="off"
                @focus="editColumn.index = data.index"
                @input="(event) => updateKod(event, data)"
              />
            </div>
          </template>
        </b-table>
        <!-- Row Loop -->
      </b-form>
    </div>
  </b-card>
</template>

<script>
import { BForm, BFormInput, BButton, BCard, BTable } from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";
import store from "@/store";

export default {
  components: {
    BForm,
    // BRow,
    // BCol,
    BButton,
    // BFormGroup,
    BFormInput,
    BCard,
    BTable,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  beforeRouteEnter(to, from, next) {
    if (to.name == "ingredientCodeChange") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/",
        text: VueI18n.t("ingredients"),
        active: true,
      });

      to.meta.breadcrumb.push({
        text: VueI18n.t("ingredientCodeChange"),
        active: true,
      });
    }
    next();
  },
  data() {
    return {
      getUserData,
      ingredients: [],
      allCodes: [],
      searchQuery: "",
      editColumn: { cell: "", index: 0 },
      fields: [
        {
          key: "ingredientname",
          label: VueI18n.t("ingredient"),
          sortable: true,
          filterable: true,
        },
        {
          key: "oldcode",
          label: VueI18n.t("code"),
          sortable: true,
          filterable: true,
          thClass: "table-code-col",
          tdClass: "table-code-col",
        },

        {
          key: "newKod",
          label: VueI18n.t("newKod"),
          sortable: false,
          filterable: true,
          editable: true,
          thClass: "table-max-width",
          tdClass: "table-max-width",
        },
      ],
    };
  },

  async mounted() {
    await this.fetchIngredients();
    this.$nextTick(() =>
      document.querySelectorAll("input.editable").forEach((x) => {
        x.addEventListener("keydown", function (e) {
          if (e.which === 38 || e.which === 40) {
            e.preventDefault();
          }
        });
      })
    );
    document.addEventListener("keyup", (e) => {
      let backOrForward = 1;
      if (e.code === "ArrowUp") {
        backOrForward = -1;
      }
      if (
        e.code === "Enter" ||
        e.code === "NumpadEnter" ||
        e.code === "ArrowDown" ||
        e.code === "ArrowUp"
      ) {
        this.editColumn.index = this.editColumn.index + backOrForward;

        this.focusSelectedInput(this.editColumn.index);
      }
    });
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    updateKod(event, data) {
      data.item.newKod = event;
    },
    async fetchIngredients() {
      const { data } = await this.$http
        .get("ingredients/getingredientcodes")
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: this.$t("xFetchError", {
                value: this.$t("ingredients"),
              }),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      this.ingredients = data.resultdata;

      if (this.searchQuery != "")
        this.ingredients = this.ingredients.filter((x) =>
          x.ingredientname
            .toLowerCase()
            .startsWith(this.searchQuery.toLowerCase())
        );
    },
    focusSelectedInput(index) {
      var input = document.querySelector(
        `input[data-id="ingredient-${index}"]`
      );
      var scrollInput = document.querySelector(
        `input[data-id="ingredient-${index - 3}"]`
      );
      if (input) {
        if (scrollInput) scrollInput.scrollIntoView({ behavior: "smooth" });
        window.setTimeout(() => {
          input.focus();
        }, 1);
      }
    },
    async updateCodes() {
      // if code exists show toast
      var ikili = this.ingredients
        .filter((x) => x.newKod != undefined)
        .map((x) => {
          return {
            id: x.id,
            deger: x.newKod,
          };
        });
      if (ikili.length == 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            text: this.$t("anyChanges"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }
      var existItem = null;
      var alreadyExists = this.ingredients.some((x) => {
        return ikili.some((y) => {
          existItem = x;
          return y.deger == x.oldcode;
        });
      });

      if (alreadyExists) {
        this.focusSelectedInput(this.ingredients.indexOf(existItem));
        this.$toast({
          component: ToastificationContent,
          props: {
            text: this.$t("xExists", {
              value: this.$t("code"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      } else {
        var result = await this.$http.put(
          `/Ingredients/UpdateIngredientCodes/0`,
          JSON.stringify(ikili)
        );
        if (result.data.status == "OK") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: VueI18n.t("successful"),
              icon: "CheckIcon",
              variant: "success",
              text: VueI18n.t("updated", { type: VueI18n.t("ingredients") }),
            },
          });
          this.fetchIngredients();
        } else
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: VueI18n.t("unsuccessful"),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: VueI18n.t("notUpdated", { type: VueI18n.t("ingredients") }),
            },
          });
      }
    },

    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.editable {
  cursor: pointer;
}
.text-right {
  text-align: right;
}
</style>
